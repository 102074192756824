import React, { useState, useEffect } from "react";
import whiteArrow from "../../assets/images/white-arrow.svg";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Modal({ show, handleClose, selectedId }) {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    resume: null,
    message: "",
    job_id: selectedId,
  });

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      job_id: selectedId,
    }));
  }, [selectedId]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFileName(file.name);
    }
    setFormData({
      ...formData,
      resume: e.target.files[0],
    });
  };

  const [fileName, setFileName] = useState("No file chosen");
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const data = new FormData();
    data.append("firstName", formData.firstName);
    data.append("lastName", formData.lastName);
    data.append("email", formData.email);
    data.append("phone", formData.phone);
    data.append("resume", formData.resume);
    data.append("message", formData.message);
    data.append("job_id", formData.job_id);

    try {
      const response = await fetch("https://webbitech.co.in/PHP-BETA/vista/api/career", {
        method: "POST",
        body: data,
      });

      if (response.ok) {
        const result = await response.json();
        console.log("Success:", result);
        toast.success("Form submitted successfully!", { autoClose: 1000 });

        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          resume: null,
          message: "",
          job_id: selectedId,
        });

        handleClose();
      } else {
        console.error("Error:", response.statusText);
      }
    } catch (error) {
      toast.error("There was a problem with your submission.");
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className={`modal career-modal ${show ? "d-block" : "d-none"}`}
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      id={`careerModal_${selectedId}`}
      tabIndex="-1"
      role="dialog">
      <div
        className="modal-dialog modal-lg modal-dialog-centered"
        role="document">
        <div className="modal-content p-2">
          <div className="modal-header">
            <h5 className="modal-title">Career Form</h5>
            <button
              type="button"
              className="btn-close"
              onClick={handleClose}></button>
          </div>
          <div className="modal-body">
            <form className="row g-3" onSubmit={handleSubmit}>
              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  placeholder=" First Name*"
                  required
                  disabled={loading}
                />
              </div>
              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control"
                  name="lastName"
                  placeholder=" Last Name*"
                  value={formData.lastName}
                  onChange={handleChange}
                  required
                  disabled={loading}
                />
              </div>
              <div className="col-md-6">
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  placeholder="Email*"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  disabled={loading}
                />
              </div>
              <div className="col-md-6">
                <input
                  type="number"
                  className="form-control"
                  name="phone"
                  placeholder="Phone"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                  disabled={loading}
                />
              </div>
              <div className="col-md-12">
                <div className="file-input">
                  <input
                    type="file"
                    id="file"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                    disabled={loading}
                  />
                  <label htmlFor="file" className="input-btn">
                    Choose File
                  </label>
                  <span style={{ marginLeft: "10px" }}>{fileName}</span>
                </div>
              </div>
              <div className="col-md-12">
                <textarea
                  name="message"
                  className="form-control"
                  rows={3}
                  value={formData.message}
                  onChange={handleChange}
                  placeholder="Description"
                  required
                  disabled={loading}></textarea>
              </div>
              <div className="col-md-12">
                <button
                  type="submit"
                  className="btn main_btn"
                  disabled={loading}>
                  {loading ? "Submitting..." : "Submit"}
                  <img src={whiteArrow} alt="" />
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Modal;
